<template>
  <div class="start-window">
    <div class="b21">{{ $t('USR_SAV') }}...</div>
  </div>
</template>
<script>
import { AUTH_SOCIAL_TOKENS } from "@/store/actions/auth";
import { mapGetters } from "vuex";
export default {
  name: "SocialAuth",
  computed: {
    // ...mapGetters(["authStatus"]),
  },

  data() {
    return {};
  },
  mounted() {
    this.parseToken();
  },
  methods: {
    isVkPageTokens: function (url) {
      const match = {};
      [...url.matchAll(/access_token_([0-9]*)=([0-9a-z]*)/gm)].forEach((i) => {
        const [_, key, value] = i;
        match[key] = value;
      });
      if (Object.keys(match).length) return match;
      return undefined;
    },
    isVk: function (url) {
      const match = url.match(
        "access_token=([-A-Za-z0-9._]*)&expires_in=([0-9]*)&user_id=([0-9]*)"
      );
      if (match && match.length === 4) return { vk_token: match[1] };
      return false;
    },
    isFb: function (url) {
      const match = url.match(
        `access_token=([A-Za-z0-9]*)&([\\S]*)&state=(facebook)`
      );
      if (match && match.length === 4) return { fb_token: match[1] };
      return false;
    },
    isIg: function (url) {
      const match = url.match(
        "access_token=([A-Za-z0-9]*)&([\\S]*)&state=(instagram)"
      );
      if (match && match.length === 4) return { ig_token: match[1] };
      return false;
    },
    saveSocialTokens(url) {
      const token = this.isFb(url) || this.isVk(url) || this.isIg(url);
      if (token) {
        this.$Axios.post("social-token", { token }).then((response) => {
          this.$store.dispatch(AUTH_SOCIAL_TOKENS);
          localStorage.setItem(
            "message",
            JSON.stringify({ command: "auth", data: token })
          );

          // window.close();
          console.log('window.close()', 1);
        });
      }
    },
    saveVkPageTokens(tokens) {
      this.$Axios.post("vk-page-tokens", { tokens }).then(() => {
        console.log('window.close()', 2);
        // window.close();
      });
    },
    parseToken: function () {
      const url = window.location.href;
      const pageTokens = this.isVkPageTokens(url);

      if (pageTokens) {
        this.saveVkPageTokens(pageTokens);
      } else {
        this.saveSocialTokens(url);
      }
    },
  },
};
</script>


